/*
SW Loader
*/
document.addEventListener("DOMContentLoaded", function () {
    const preload = document.querySelector(".swloader");
    const pageContent = document.querySelector(".swcontent");
    if (preload === null) {
        return;
    }
    if (pageContent === null) {
        return;
    }
    setTimeout(function () {
        preload.style.transition = "transform 0.6s, opacity 0.6s";
        preload.style.transform = "translateY(-200px)";
        preload.style.opacity = 0;
        setTimeout(function () {
            preload.style.display = "none";
            if (pageContent !== null) {
                pageContent.style.display = "block";
            }
        }, 500);
    }, 600);
});
/*
Abas
*/
document.addEventListener("DOMContentLoaded", function () {
    var tabs = document.querySelectorAll("ul.abas > li");
    var tabContents = document.querySelectorAll(".abas-content");
    tabs.forEach(function (tab) {
        tab.addEventListener("click", function () {
            var tabId = this.getAttribute("data-abas");
            if (tabId) {
                tabs.forEach(function (tab) {
                    tab.classList.remove("active");
                });
                tabContents.forEach(function (tabContent) {
                    tabContent.classList.remove("active");
                });
                this.classList.add("active");
                document.getElementById(tabId).classList.add("active");
            }
        });
    });
});
/*
Mascara telefone - class:"tel"
*/
const inputsTel = document.querySelectorAll("input.tel");
inputsTel.forEach((input) => {
    input.addEventListener("input", mascaraTelefone);
    input.addEventListener("keydown", handleDeleteAndBackspace);
});
function mascaraTelefone(event) {
    let telefone = event.target.value.replace(/\D+/g, "");
    let tamanho = telefone.length;

    if (tamanho > 10) {
        telefone = telefone.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
    } else if (tamanho > 5) {
        telefone = telefone.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
    } else if (tamanho > 2) {
        telefone = telefone.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
    } else {
        telefone = telefone.replace(/^(\d*)/, "($1");
    }
    event.target.value = telefone;
}
function handleDeleteAndBackspace(event) {
    if (event.key === "Delete" || event.key === "Backspace") {
        event.target.value = event.target.value.replace(/[^\d]/g, "");
    }
}
/*
Mascara CPF ou CNPJ - class:"cpf" ou class:"cnpj"
*/
const inputsCpf = document.querySelectorAll("input.cpf");
const inputsCnpj = document.querySelectorAll("input.cnpj");
inputsCpf.forEach((input) => {
    input.addEventListener("input", mascaraCpf);
    input.addEventListener("keydown", handleDeleteAndBackspace);
});
inputsCnpj.forEach((input) => {
    input.addEventListener("input", mascaraCnpj);
    input.addEventListener("keydown", handleDeleteAndBackspace);
});
function mascaraCpf(event) {
    let cpf = event.target.value.replace(/\D+/g, "");
    let tamanho = cpf.length;
    cpf = cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2}).*/, "$1.$2.$3-$4");
    event.target.value = cpf;
}
function mascaraCnpj(event) {
    let cnpj = event.target.value.replace(/\D+/g, "");
    let tamanho = cnpj.length;
    cnpj = cnpj.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/,
        "$1.$2.$3/$4-$5"
    );
    event.target.value = cnpj;
}
function handleDeleteAndBackspace(event) {
    if (event.key === "Delete" || event.key === "Backspace") {
        event.target.value = event.target.value.replace(/[^\d]/g, "");
    }
}
/*
Mascara moeda - class:"moeda"
*/
const inputsMoeda = document.querySelectorAll("input.moeda");
function formatarMoeda(valor) {
    valor = valor.replace(/\D+/g, "");
    valor = valor.replace(/(\d)(\d{2})$/, "$1,$2");
    valor = valor.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return valor;
}
function atualizarValorInput(input) {
    const valor = input.value;
    const valorFormatado = formatarMoeda(valor);
    input.value = valorFormatado;
}
inputsMoeda.forEach((input) => {
    input.addEventListener("input", () => {
        atualizarValorInput(input);
    });
});
/*
Esconder e exibir Password
*/
const senha = document.getElementById("senha");
const olho = document.createElement("i");
if (senha) {
    olho.className = "bx bx-hide";
    olho.id = "olho";
    senha.parentNode.appendChild(olho);
    let isEyeOpen = true;
    olho.addEventListener("click", function () {
        if (isEyeOpen) {
            olho.className = "bx bx-show-alt";
            senha.type = "text";
        } else {
            olho.className = "bx bx-hide";
            senha.type = "password";
        }
        isEyeOpen = !isEyeOpen;
    });
    document.addEventListener("click", function (event) {
        if (
            event.target !== olho &&
            event.target !== senha &&
            event.target.parentNode !== senha.parentNode
        ) {
            olho.className = "bx bx-hide";
            senha.type = "password";
            isEyeOpen = true;
        }
    });
}
/*
Navbar Navmobi
*/
document.addEventListener("DOMContentLoaded", function () {
    const navExpand = document.getElementById("navmobi-expand"),
        navExpandList = document.getElementById("navmobi-expand-list"),
        navExpandIcon = document.getElementById("navmobi-expand-icon");
    if (navExpand) {
        navExpand.addEventListener("click", () => {
            if (navExpandList) {
                navExpandList.classList.toggle("show-list");
            }
            if (navExpandIcon) {
                navExpandIcon.classList.toggle("rotate-icon");
            }
        });
    }
    const sections = document.querySelectorAll("section[id]");
    const scrollActive = () => {
        const scrollDown = window.scrollY;
        sections.forEach((current) => {
            const sectionHeight = current.offsetHeight,
                sectionTop = current.offsetTop - 58,
                sectionId = current.getAttribute("id"),
                sectionsClass = document.querySelector(
                    ".navmobi__list a[href*=" + sectionId + "]"
                );
            if (sectionsClass) {
                if (
                    scrollDown > sectionTop &&
                    scrollDown <= sectionTop + sectionHeight
                ) {
                    sectionsClass.classList.add("active-link");
                } else {
                    sectionsClass.classList.remove("active-link");
                }
            }
        });
    };
    window.addEventListener("scroll", scrollActive);
});
/*
side-bar
*/
document.addEventListener("DOMContentLoaded", function () {
    const menuBtn = document.querySelector(".side-bar-btn");
    const sideBar = document.querySelector(".side-bar");
    const closeBtn = document.querySelector(".side-bar-close");
    const subBtns = document.querySelectorAll(".side-bar-sub-btn");
    if (menuBtn) {
        menuBtn.addEventListener("click", function () {
            sideBar.classList.add("active");
            menuBtn.style.visibility = "hidden";
        });
        closeBtn.addEventListener("click", function () {
            sideBar.classList.remove("active");
            menuBtn.style.visibility = "visible";
        });
        subBtns.forEach(function (subBtn) {
            subBtn.addEventListener("click", function () {
                const subMenu = subBtn.nextElementSibling;
                subMenu.classList.toggle("active");
                subBtn.querySelector(".dropdown").classList.toggle("rotate");
            });
        });
        const fcrLinks = document.querySelectorAll(".fcr");
        fcrLinks.forEach(function (link) {
            link.addEventListener("click", function () {
                sideBar.classList.remove("active");
                menuBtn.style.visibility = "visible";
            });
        });
    }
});
/*
Moodal
*/
const backdrop = document.createElement("div");
backdrop.id = "backdrop_moodal";
backdrop.style.display = "none";
document.body.appendChild(backdrop);
const buttons = document.querySelectorAll("[data-moodal]");
buttons.forEach((button) => {
    button.addEventListener("click", () => {
        const moodalId = button.getAttribute("data-moodal");
        const moodal = document.getElementById(moodalId);
        moodal.classList.add("show_moodal");
        moodal.classList.remove("offscreen_moodal");
        backdrop.style.display = "block";
    });
});
const closeButtons = document.querySelectorAll(".close_moodal");
closeButtons.forEach((button) => {
    button.addEventListener("click", () => {
        closeMoodal(backdrop);
    });
});
backdrop.addEventListener("click", () => {
    closeMoodal(backdrop);
});
document.addEventListener("keydown", (event) => {
    if (event.key === "Escape") {
        closeMoodal(backdrop);
    }
});
function closeMoodal(backdrop, button = null) {
    const openMoodal = document.querySelector(".moodal.show_moodal");
    openMoodal.classList.remove("show_moodal");
    openMoodal.classList.add("offscreen_moodal");
    backdrop.style.display = "none";
}
function openMoodal(moodalId) {
    const moodal = document.getElementById(moodalId);
    moodal.classList.add("show_moodal");
    moodal.classList.remove("offscreen_moodal");
    backdrop.style.display = "block";
}
/*
NavBar
*/
document.addEventListener("DOMContentLoaded", () => {
    const navbars = document.querySelectorAll(".navbar");

    navbars.forEach((navbar) => {
        const toggle = navbar.querySelector(".toggle_navbar");
        const menu = navbar.querySelector(".menu_navbar");

        toggle.addEventListener("click", () => {
            menu.classList.toggle("active");
        });
    });
});
